.nav--container {
  position: sticky;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-width: 375px;
  width: 100%;
  height: 5rem;
}

.link--container{
  display: flex;
  flex-direction: row;
  margin-right: 25px;
  gap: 20px;
  align-items: center;
  transition: 0.3s;
}

.nav--container a:link,
.nav--container a:active,
.nav--container a:visited {
  text-decoration: none;
  font-family: 'Source Code Pro', monospace;
  font-weight: 300;
  color: rgb(0, 0, 0);
  cursor: pointer;
  transition: 0.1s;
}

.nav--container a:hover {
  color: rgb(129, 129, 129);
}

.nav--container h1 {
  margin-left: 25px;
  font-family: 'Mulish', sans-serif;
  font-weight: 11;
}

.mail--icon {
  margin: 0;
  padding: 0;
  font-size: 25px;
  opacity: 0.7;
}

.insta--logo {
  width: 18px;
  margin-top: 5px;
}

.insta--logo:hover {
  opacity: 0.6;
}

.linked--in {
  font-size: 20px;
  padding-right: 10px;
}


@media (max-width: 768px) {
    .linked--in{
      border-right: none;
    }
    .nav--container h1 {
      font-size: 20px;  
    }
    .nav--container {
      justify-content: space-evenly;
    }
}

@media (max-width: 500px) { 
  .link--container {
    gap: 30px;
    transition: 0.3s;
  }
}