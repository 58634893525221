.home--container {
  width: 100%;
}

.all--projects--container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 2rem;
}

@media (max-width: 1000px) {
  .all--projects--container {
    flex-direction: column;
    align-items: center;
  }
}

.project--container {
  max-width: 800px;
  flex: 0 0 calc(50% - 30px);
}

.project--container--locked {
  max-width: 800px;
  flex: 0 0 calc(50% - 30px);
  position: relative;
  outline: 1px solid rgb(39, 38, 38);
}

.password-input-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  padding: 5px;
}

.locked {
  text-align: center;
  color: white;
  margin-bottom: 1rem;
}

.input--button--container {
  display: flex;
  flex-direction: row;
}

input {
  border: none;
  background-color: rgba(255, 255, 255, 0.7);
  height: 2rem;
  font-size: larger;
}

.wrong-password {
  background-color: rgba(247, 101, 101, 0.7);
}

input:focus {
  outline: none;
}

button {
  background-color: rgba(255, 255, 255, 0.7);
  border: none;
  cursor: pointer;
  font-size: larger;
}

button:hover {
  background-color: rgba(255, 255, 255, 1);
}

.video--cover {
  width: 100%;
  height: 100%;
}

.cover {
  width: 100%;
  height: 100%;
  filter: blur(4px);
}